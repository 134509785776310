@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.App {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  background-color: #e1ddd4;
  color: #9d9784;
  min-width: 400px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-button {
  background-color: #9d9784; /* Primary color */
  color: #f9f9f9; /* White text */
  padding: 12px 48px; /*Space inside the button */
  font-size: 16px; /* Font size */
  font-weight: 600; /* Bold text */
  border: none; /* Remove default border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth transition */
}

.custom-button:hover {
  background-color: #8b8b8b; /* Darken the button color on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
  transform: translateY(-2px); /* Slightly raise the button on hover */
}

.custom-button:active {
  background-color: #003d80; /* Even darker color when pressed */
  transform: translateY(0); /* Reset position when clicked */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow on click */
}


/* Container for the input field */
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;

}

/* Label for input field */
.input-label {
  font-size: 1rem;
  margin-bottom: 30px;
  border: 2px solid #9d9784;
  color: #555;
  font-weight: 600;
  letter-spacing: 1.75px;
  text-align: center;
  border-radius: 8px; /* Rounded corners */
  padding: 8px 8px; /* Space inside the button */

  
}

/* Styled input field */
.input-box {
  padding: 12px 20px;
  border-radius: 8px;
  border: 2px solid #ddd;
  font-size: 1rem;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  color: #333;
  outline: none;
  
}

.input-box:focus {
  border-color: #4a90e2;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.3);
}

.input-box::placeholder {
  color: #aaa;
  font-style: italic;
  
}

/* Transition effect when input is focused */
.input-box:focus::placeholder {
  color: transparent;
}

/* Add an error message below the input */
.error-message {
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 5px;
  display: none; /* Show this only when there's an error */
}

.paragraph {
  /* flex-direction: column; */
  /* width: 50%; */
  max-width: 400px;
  /* font-size: larger; */
  color: #9d9784;
}

.concept {
 text-align: justify;
  max-width: 400px;
  /* font-size: larger; */
  color: #9d9784;
}


.container {
  /* display: flex; */
  /* flex-direction: column; */
  width: 100%;
  max-width: 400px;
  /* margin: auto; */
}
.center {
  justify-content: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Protected{
  min-height: 100vh;
}

nav, ul, li, h1, p {
  margin: 0;
  padding-top: 0;
}
.geguzine {
  color:#9d9784;
  letter-spacing: 2px;
  padding-top: 36px;
  padding-bottom: 24px;
}

.left {
  text-align: left;
}