/* Agenda.css */

.agendacontainer {
    max-width: 400px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.agendalist {
    list-style: none;
    padding: 0;
}

.agendaitem {
    display: flex;
    align-items: left;
    text-align: left;
    padding: 10px 0;

    width: 100%;
    border-bottom: 1px solid #eee;
}

.agendaitem:last-child {
    border-bottom: none;
}

.agendatime {
    width: 155px;
    font-size: 1rem;
    font-weight: bold;
    color: #555;
    text-align: center;
    transform: translate(0%, 25%);

}

.agendadetails{
    width: 100%;
    padding-left: 20px;
}

.agendadetails h4 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    width: 100%;
}

.agendadetails p {
    margin: 2px 0 0 0;
    font-size: 0.9rem;
    color: #777;
    width: 100%;
}

.agendadetails a {
    color: #9d9784;
    font-size: 0.9rem;
  }

.subschedule {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    align-items: center;
    text-align: center;
  }

.agendatimeul {
    width: 150px;
    font-size: 1rem;
    /* font-weight: bold; */
    color: #555;
    text-align: center;
    transform: translate(0%, 0%);

}

.agendacontainer ul {
    padding: 0;
}