  .confirmation-form {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 100%;
    box-sizing: border-box;
  }

  .attendance-box {
    align-items: center;
  }
  
  .attendee-box {

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  hr {
    margin-top: 3px;
  }
  
  span {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 2px;

  }
    
  .comment-box {
    width: 95%;
    margin-top: 10px;
    height: 50px;
    padding: 7px;
    border-radius: 5px;
    border: 2px solid #ddd;
    resize: none;
    color: #9d9784;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
  }

  textarea::placeholder {
    color: #9d9784ab;
    font-style: italic;
    font-size: small;
  }
  
  .food-dropdown {
    padding: 8px;
    border-radius: 5px;
    border: 2px solid #ddd;
    float: right;
    color: #9d9784;
    background-color: #f9f9f9;
    transition: all 0.3s ease;
  }

  input[type="radio"] {
    display: none;
  }
  
  Style the custom radio button
  .custom-radio {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid #9d9784; /* Outline color */
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
    transition: border-color 0.3s ease;
  }
  
  /* Add a dot inside when checked */
  input[type="radio"]:checked + .custom-radio::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #9d9784; /* Checked color */
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Change color on hover */
  .custom-radio:hover {
    border-color: #9d9784;
  }

  .answer {
    align-items: center;
    margin: 10px;
    font-size: small;
  }

  .instructions {
    margin-bottom: 10px;
    align-items: left;
  }

  .instructions p {
    margin-bottom: 10px;  
  }